
import {Vue, Component } from 'vue-property-decorator'
import MainWithSidePanelSignupLayout from '@/layouts/MainWithSidePanelSignupLayout.vue'
import router from '@/router'
import { logger } from '@/utils/logger'
import auth from '@/services/auth'
import modules from '@/store/modules'
import app from '@/store/modules/app'
import customer from '@/services/customer'
import { CustomerValidatePayload } from '@/models/dto'
import { RawLocation } from 'vue-router'
import { TranslateResult } from 'vue-i18n'
import AlertBox from '@/components/AlertBox.vue'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'

@Component({
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('signup.TITLE')} - %s`,
    }
  },
  components: {
    MainWithSidePanelSignupLayout,
    AlertBox
  },
})
export default class SignUp extends Vue {
  firstName = ''
  lastName = ''
  phone = ''
  email = ''
  hash = ''
  password = ''
  passwordHidden = true
  receiveSMSTripUpdatesCheckbox = false
  phoneRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.PHONE.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
      this.$t('signup.FORM.PHONE.RULES.VALID').toString(),
  ]
  firstNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.FIRST_NAME.RULES.REQUIRED').toString(),
  ]
  lastNameRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.LAST_NAME.RULES.REQUIRED').toString(),
  ]
  emailRules: ((v: string) => boolean | string)[] = [
    (v: string): boolean | string =>
      !!v || this.$t('signup.FORM.EMAIL.RULES.REQUIRED').toString(),
    (v: string): boolean | string =>
      /.+@.+/.test(v) || this.$t('signup.FORM.EMAIL.RULES.VALID').toString(),
  ]
  errorMessage = false
  isSubmitting = false
  success = false
  valueProps: { icon: string; text: TranslateResult }[] = [
    { icon: '$color-blue-progress', text: this.$t('signup.VALUE_PROP.PRICE') },
    { icon: '$color-cash', text: this.$t('signup.VALUE_PROP.CANCELLATION') },
    { icon: '$color-phone', text: this.$t('signup.VALUE_PROP.SUPPORT') },
    { icon: '$color-marker', text: this.$t('signup.VALUE_PROP.TRACKING') },
  ]
  isReservationInvite = false
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false
  hasNoQuoteHash = false
  isValidCustomer = true
  showMainPanel = false

  get supportEmail(): string {
    return modules.systemParameter.supportEmail
  }

  get supportNumber(): string {
    return getSupportNumber(modules.auth.customer?.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  created(): void {
    if (modules.auth.isTokenSet && !this.isIncompleteCustomer) {
      router.push({ name: 'quotes' })
    }
  }

  async mounted(): Promise<void> {
    this.receiveSMSTripUpdatesCheckbox = true

    await this.populateUserDetails()

    if (this.isValidCustomer) {
      if (this.isCustomerInvite) {
        const firstNameElement = this.$refs.firstName as any
        firstNameElement.focus()
      } else {
        const passwordElement = this.$refs.password as any
        passwordElement.focus()
      }
    }
  }

  async populateUserDetails(): Promise<void> {
    const { params, query } = this.$route

    if (query?.entityType === 'reservation') {
      this.isReservationInvite = true
    }

    // Route might contain quoteHash query param,
    // meaning this is a refresh for a new user signup.
    // That user must be validated and that info populated
    // into this page.
    if (query?.quoteHash || query?.userHash) {
      const validateUserPayload: CustomerValidatePayload = { verifyCustomerDetails: true }
      if (query?.quoteHash) {
        validateUserPayload.quoteHash = query.quoteHash as string
      } else {
        this.hasNoQuoteHash = true
      }

      if (query?.userHash) {
        validateUserPayload.userHash = query.userHash as string
      }
      let userCurrentlySigningUp
      try {
        userCurrentlySigningUp = await auth.validateCustomer(
          validateUserPayload
        )
      } catch (error) {
        this.isValidCustomer = false
        logger.error(error)
        return
      } finally {
        this.showMainPanel = true
      }

      const userCurrentlySigningUpData = userCurrentlySigningUp.data
      this.firstName = userCurrentlySigningUpData.firstName
      this.lastName = userCurrentlySigningUpData.lastName
      this.email = userCurrentlySigningUpData.email
      this.phone = userCurrentlySigningUpData.phone
      this.hash = userCurrentlySigningUpData.hash
    } else if (this.isIncompleteCustomer) {
      this.firstName = params.firstName || ''
      this.lastName = params.lastName || ''
      this.email = params.email || ''
      this.phone = params.phone || ''
    } else {
      // Otherwise we want to get this data from the route params
      // because this was a redirect from the signup email directly.
      this.firstName = params.firstName || ''
      this.email = params.email || ''
      this.hash = params.hash || ''
    }
  }

  get isCustomerInvite(): boolean {
    return this.$route.query.isCustomerInvite === 'true'
  }

  get isIncompleteCustomer(): boolean {
    return this.$route.query.isIncompleteCustomer === 'true'
  }

  get phoneRaw(): string {
    return this.phone.replace(/\D/g, '')
  }

  get subHeadingMessage(): TranslateResult {
    if (this.isIncompleteCustomer) {
      return this.$t('signup.SUBHEAD_INCOMPLETE_CUSTOMER')
    }

    if (this.isReservationInvite) {
      return this.$t('signup.SUBHEAD_RESERVATION_INVITE')
    }

    if (this.hasNoQuoteHash) {
      return this.$t('signup.SUBHEAD_USER_HASH_ONLY')
    }

    return this.$t('signup.SUBHEAD')
  }

  get receiveSMSTripUpdates(): boolean {
    return this.isReservationInvite && this.receiveSMSTripUpdatesCheckbox
  }

  async submit() {
    if (this.isSubmitting) {
      return
    }
    try {
      this.shouldValidatePassword = true
      this.asyncPasswordResponse = await auth.validatePassword(this.password)

      this.$nextTick(async () => {
        const form = this.$refs.form as any
        const formIsValid = await form.validate()
        if (formIsValid) {
          this.isSubmitting = true

          if (!this.isIncompleteCustomer) {
            await auth.resetPassword({
              hash: this.hash,
              password: this.password,
            })

            this.success = true

            await modules.auth.login({
              email: this.email,
              password: this.password,
            })
          }

          if (this.isCustomerInvite) {
            try {
              const customerResponse = await customer.byId(modules.auth.userId)
              const customerPayload = customerResponse.data.customer
              customerPayload.firstName = this.firstName
              customerPayload.lastName = this.lastName
              customerPayload.phone = this.phoneRaw

              const updatedCustomerResponse = await customer.edit(customerPayload)
              const updatedCustomer = updatedCustomerResponse.data.customer

              if (!updatedCustomer?.smsConfirmed && this.receiveSMSTripUpdates) {
                await customer.updateSMS(modules.auth.userId, {
                  phoneNumber: this.phoneRaw,
                })
              }
            } catch (error) {
              console.error(error)
            }
          }

          await modules.auth.fetchUserDetails()
          await modules.auth.refreshRolesAndPermissions()
          this.isSubmitting = false
          let to = {
            name: 'quote-index',
          } as RawLocation

          if (this.$route.query?.redirectFrom) {
            to = `${this.$route.query.redirectFrom}&fromSignup=true${
              this.receiveSMSTripUpdates ? '&receiveSMS=true' : ''
            }` as RawLocation
          }

          this.$router.push(to)
        }
      })
    } catch (error) {
      this.isSubmitting = false
      logger.error(error)
    }
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }
}
