var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"fill-height padding-a-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"flex-shrink-0 px-8 border-r-1 border-l-0 border-y-0 border-solid border-border-gray",class:{
        'background-header-gray': _vm.flipColors,
        'background-white': !_vm.flipColors,
      },style:({
        width: `${_vm.sideContentWidth}px`,
        'max-width': `${_vm.sideContentWidth}px`,
        'padding-top': `${_vm.sideContentTopPadding}px`,
        'box-sizing': 'border-box',
      })},[_vm._t("side-content")],2):_vm._e(),_c('v-col',{staticClass:"flex-shrink-1 padding-x-6",class:{
        'background-header-gray': !_vm.flipColors,
        'background-white': _vm.flipColors || _vm.$vuetify.breakpoint.xs,
        'padding-y-6': _vm.$vuetify.breakpoint.smAndUp,
      }},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }